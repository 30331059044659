import React, { useCallback, useMemo, useState } from 'react';
import isSameDay from 'date-fns/isSameDay';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Euro from '@material-ui/icons/Euro';
import Loader from '@/components/Loader';
import TransactionType from '@/components/TransactionType';
import StatusIcon from '@/components/Transactions/StatusIcon';
import ExpandIcon from '@/components/ExpandIcon';
import { getDisplayTime, convertCentsToEuro } from '@/utils/stringUtil';
import { REFRESH_TRANSACTIONS_EVENT } from '@/constants/customEventNames';
import { PayconiqTypes, VoucherTypes } from '@/constants/transactions';
import useScroll from '@/hooks/useScroll';
import useCustomEvent from '@/hooks/useCustomEvent';
import useTransaction from '@/hooks/useTransaction';
import useTransactionPolling from '@/hooks/useTransactionPolling';
import TransactionPolling from '@/components/Transactions/TransactionPolling';
import TransactionsHeader from '@/components/TransactionsHeader';
import isEmpty from '@/lib/isEmpty';
import useStyles from './styles';

function TodaysTransactions() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const initialTableState = {
    id: null,
    index: null,
  };
  const [expandedRow, setExpandedRow] = useState(initialTableState);

  const { transactions, getTransactions, isLoading } = useTransaction() || {};

  const { resetPollingError } = useTransactionPolling();

  const todayTransactions = useMemo(
    () =>
      transactions?.filter((data) =>
        isSameDay(new Date(), new Date(data.succeededAt || data.creationDate)),
      ),
    [transactions],
  );

  const handleRefreshTransactions = useCallback(async () => {
    resetPollingError();

    await getTransactions();
  }, [getTransactions, resetPollingError]);

  useCustomEvent(REFRESH_TRANSACTIONS_EVENT, handleRefreshTransactions);

  const handleScroll = useCallback(async () => {
    await getTransactions('PAGINATION').catch((error) => {
      resetPollingError();
      return Promise.reject(error);
    });
  }, [getTransactions, resetPollingError]);

  useScroll(handleScroll, isLoading);

  const handleExpandClick = (id, index) => () => {
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow.id === id
        ? initialTableState
        : {
            id,
            index,
          },
    );
  };

  return (
    <>
      <TransactionPolling />
      <TransactionsHeader title="menu.latestTransactions" showFullScreen />
      <div className={isEmpty(todayTransactions) ? classes.emptyPageHeight : ''}>
        <TableContainer component={Paper}>
          <Table aria-label="all transactions table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.time }}>{t('transactions.time')}</TableCell>
                <TableCell classes={{ root: classes.amount }} align="right">
                  {t('transactions.amount')}
                </TableCell>
                <TableCell classes={{ root: classes.name }} align="center">
                  {t('transactions.firstName')}
                </TableCell>
                {isLargeScreen && (
                  <TableCell classes={{ root: classes.status }} align="center">
                    {t('transactions.message')}
                  </TableCell>
                )}
                <TableCell classes={{ root: classes.type }} align="center">
                  {t('transactions.type')}
                </TableCell>
                {isLargeScreen && (
                  <TableCell classes={{ root: classes.status }} align="center">
                    {t('transactions.status.0')}
                  </TableCell>
                )}
                {!isLargeScreen && <TableCell classes={{ root: classes.expand }} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(todayTransactions) &&
                todayTransactions.map((transaction, index) => {
                  const {
                    paymentId,
                    creationDate,
                    amount,
                    name,
                    description,
                    succeededAt,
                    type,
                    vasDetails,
                  } = transaction;
                  const time = getDisplayTime(succeededAt || creationDate);
                  const amountInEuros = convertCentsToEuro(amount);
                  const showPayconiq = PayconiqTypes.includes(type);
                  const showVoucher = VoucherTypes.includes(type);
                  return (
                    <React.Fragment key={paymentId}>
                      <TableRow
                        hover
                        className={clsx(
                          expandedRow.index !== null &&
                            index > expandedRow.index &&
                            classes.reverse,
                        )}
                      >
                        <TableCell classes={{ root: classes.time }} title={time}>
                          {time}
                        </TableCell>
                        <TableCell
                          classes={{ root: classes.amount }}
                          title={amountInEuros}
                          align="right"
                        >
                          <Euro fontSize="inherit" className={classes.currency} /> {amountInEuros}
                        </TableCell>

                        <TableCell classes={{ root: classes.name }} title={name} align="center">
                          {name}
                        </TableCell>
                        {isLargeScreen && (
                          <TableCell
                            classes={{ root: classes.message }}
                            title={description}
                            align="center"
                          >
                            {description || '-'}
                          </TableCell>
                        )}
                        <TableCell classes={{ root: classes.type }} align="center" title={type}>
                          <TransactionType
                            showPayconiq={showPayconiq}
                            showVoucher={showVoucher}
                            vasDetails={vasDetails}
                          />
                        </TableCell>
                        {isLargeScreen && (
                          <TableCell
                            classes={{ root: classes.status }}
                            align="center"
                            title={t('success')}
                          >
                            <StatusIcon status="success" />
                          </TableCell>
                        )}
                        {!isLargeScreen && (
                          <TableCell
                            classes={{ root: classes.expand }}
                            title={t('transactions.button.more')}
                          >
                            <ExpandIcon
                              expanded={expandedRow.id === paymentId}
                              handleExpandClick={handleExpandClick(paymentId, index)}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                      {expandedRow.id === paymentId && !isLargeScreen && (
                        <TableRow className={clsx(classes.expandedRow, classes.reverse)}>
                          <TableCell colSpan={5}>
                            <div className={classes.expandedSection}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="caption" component="p">
                                    <strong>{t('transactions.message')}:</strong>&nbsp;
                                    {description || '-'}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="caption" component="p">
                                    <strong>{t('transactions.status.0')}:</strong>&nbsp;
                                    <span className={classes.statusSucceeded}>
                                      {t('transactions.status.succeeded')}
                                    </span>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}

              {!isLoading && isEmpty(todayTransactions) && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    {t('transactions.error.noTransactions')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.loadSectionHeight}>
          <Loader isLoading={isLoading} />
        </div>
      </div>
    </>
  );
}

export default TodaysTransactions;
