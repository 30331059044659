/* eslint react/jsx-filename-extension: 0 */
import React, { useCallback, useRef } from 'react';
import isSameDay from 'date-fns/isSameDay';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { getRoute } from '@/router/paths';
import { browserStorageGet } from '@/utils/browserStorage';
import BROWSER_STORAGE_KEYS from '@/constants/browserStorageKeys';
import { SESSION_EXPIRED } from '@/constants/errorCodes';
import useTransaction from '@/hooks/useTransaction';
import { Transaction } from '@/types/transactions';
import { ApiError } from '@/types/api';

const notification = new Audio(getRoute('/audio/notifications/notification.mp3'));
const REFETCH_INTERVAL = 5000;
const MAX_POLLING_ERROR_COUNT = 4;
const SNACKBAR_KEY = 'todaysTransactions';

type UseTransactionPolling = {
  startPolling: () => void;
  stopPolling: () => void;
  resetPollingError: () => void;
};

function useTransactionPolling(): UseTransactionPolling {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getTransactions } = useTransaction() || {};

  const pollingTimer = useRef<NodeJS.Timer | null>(null);
  const pollingErrorCount = useRef(0);
  const hasError = useRef(false);

  const resetPollingError = useCallback(() => {
    pollingErrorCount.current = 0;
    hasError.current = false;
    closeSnackbar(SNACKBAR_KEY);
  }, [closeSnackbar]);

  const handlePollingError = useCallback(() => {
    pollingErrorCount.current += 1;
    hasError.current = true;
    if (pollingErrorCount.current === MAX_POLLING_ERROR_COUNT) {
      pollingErrorCount.current = 0;

      return enqueueSnackbar(t('transactions.error.connection'), {
        action: () => (
          <Button
            color="primary"
            size="small"
            key="refresh"
            onClick={() => window.location.reload()}
          >
            {t('button.refresh').toUpperCase()}
          </Button>
        ),
        persist: true,
        preventDuplicate: true,
        key: SNACKBAR_KEY,
      });
    }

    return null;
  }, [t, enqueueSnackbar]);

  const poll = useCallback(() => {
    if (getTransactions) {
      getTransactions('POLLING')
        .then(async (response) => {
          const isTodayTransactionsExisted = (response as Transaction[])?.some((data) =>
            isSameDay(new Date(), new Date(data.succeededAt || data.creationDate)),
          );
          if (
            browserStorageGet(BROWSER_STORAGE_KEYS.NOTIFICATION_PREFERENCE) === 'true' &&
            isTodayTransactionsExisted
          ) {
            await notification.play();
          }
          if (hasError.current) {
            resetPollingError();
          }
        })
        .catch(async (error: ApiError) => {
          if (error?.code === SESSION_EXPIRED) {
            return Promise.reject(error);
          }
          return handlePollingError();
        });
    }
    return null;
  }, [getTransactions, handlePollingError, resetPollingError]);

  const startPolling = useCallback(() => {
    pollingTimer.current = setInterval(poll, REFETCH_INTERVAL);
  }, [poll]);

  const stopPolling = useCallback(() => {
    if (pollingTimer.current) {
      clearInterval(pollingTimer.current);
      pollingTimer.current = null;
    }
  }, []);

  return {
    startPolling,
    stopPolling,
    resetPollingError,
  };
}

export default useTransactionPolling;
